import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PeopleDensityService {
  baseUrl = environment.PY_baseURL + 'PeopleDensity';
  constructor(private http: HttpClient) {}

  getStoreOverview(
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    timeZone: string
  ) {
    // ${localStorage.getItem("safebuildingSpaceTypeId")}
    return this.http.get(
      this.baseUrl +
        `/AreaAlertAggregate?SpaceId=${localStorage.getItem(
          'safebuildingSpaceId'
        )}&SpaceTypeId=6&StartDate=${startDate}&EndDate=${endDate}&StartTime=${startTime}&EndTime=${endTime}&TimeZone=${timeZone}`
    );
  }

  getNotificationList(
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    timeZone: string
  ) {
    return this.http.get(
      this.baseUrl +
        `/AreaNotifications?SpaceId=${localStorage.getItem(
          'safebuildingSpaceId'
        )}&SpaceTypeId=${localStorage.getItem(
          'safebuildingSpaceTypeId'
        )}&StartDate=${startDate}&EndDate=${endDate}&StartTime=${startTime}&EndTime=${endTime}&TimeZone=${timeZone}`
    );
  }

  getFloorPlan(zoneId: any) {
    return this.http.get(
      `${environment.PD_baseURL}space-management/space/zone/image/base64/${zoneId}`
    );
  }

  getCumulativeData(
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    timeZone: string
  ) {
    return this.http.get(
      `${this.baseUrl}/Latest?SpaceId=${localStorage.getItem(
        'safebuildingSpaceId'
      )}&TimeZone=${timeZone}`
    );
  }

  getPeopleData(
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    timeZone: string
  ) {
    return this.http.get(
      this.baseUrl +
        `/Notifications?SpaceId=${localStorage.getItem(
          'safebuildingSpaceId'
        )}&SpaceTypeId=${localStorage.getItem(
          'safebuildingSpaceTypeId'
        )}&StartDate=${startDate}&EndDate=${endDate}&StartTime=00:00&EndTime=${endTime}&TimeZone=${timeZone}`
    );
  }
}
